.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.2rem;
}

.mainModal {
  height: 95%;
  overflow-y: scroll;
}
.button {
  width: 40%;
}
.modal {
  margin-bottom: 50px;
}
.button {
  width: 40%;
}
@media screen and (max-width: 650px) {
  .wrapper {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
  .button {
    width: 100%;
  }

  .mainModal {
    height: 85%;
    overflow-y: scroll;
  }
  .button {
    width: 100%;
  }
  .headerCourse {
    font-size: 0.85rem !important;
  }
}

@media only screen and (max-height: 900px)
{ 
  .mainModal {
    height: 650px;;
    overflow-y: scroll;
  }
 }
