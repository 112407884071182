.container1 {
  max-width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
.navbar1.scrolled {
  background-color: #081c39;
  z-index: 9999;
}

.navbar1 {
  height: 80px;
  position: fixed; /* Change to fixed */
  top: 0; /* Specify the top position */
  left: 0; /* Specify the left position */
  right: 0; /* Specify the right position */
  margin-bottom: 40px;
  background-color: #092043;
  z-index: 9999;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 17px;
  color: rgb(34, 71, 115);
  text-decoration: none;
  font-weight: 600;
  position: relative;
}

.nav-elements ul a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.4s ease-in-out;
  top: 25px;
}
.nav-elements ul .activeLink a::before {
  width: 100%;
}

.nav-elements ul a:hover::before {
  width: 100%;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

@media (max-width: 800px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .buttons {
    display: none;
  }
  .nav-elements {
    position: absolute;
    left: 0;
    top: 73px;
    width: 100%;
    height: 0;
    z-index: 999;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0s ease-in-out;
  }

  .nav-elements.active {
    height: 352px;
    background: #071b38;
    transition: all 0.3s ease-in-out;
    z-index: 99999;
  }

  .nav-elements ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    display: inline-block;
    width: 100%;
    z-index: 999;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
  }
  .nav-elements ul li a {
    color: white;
    margin-bottom: 10px;
  }
  .nav-elements ul a::before {
    height: 0px;
  }
}
#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: 0.3s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: white;
  border-radius: 5px;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle .bars {
  margin-left: 13px;
}

#checkbox:checked + .toggle #bar2 {
  transform: rotate(135deg);
  margin-left: 0;
  transform-origin: center;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle #bar1 {
  transform: rotate(45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}

#checkbox:checked + .toggle #bar3 {
  transform: rotate(-45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}

@media screen and (max-width: 500px) {
  .nav-elements .active {
    height: 174px;
    background: #071b38;
    transition: all 0.3s ease-in-out;
    z-index: 99999;
  }
}
