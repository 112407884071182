.container {
  margin-top: 50px;
  margin-bottom: 100px;
}
.badges {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
}
.title {
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 50px;
}
.badge h1 {
  color: rgb(255, 253, 253);
  font-size: 40px;
  font-weight: 700;
}
.badge h2 {
  font-size: 20px;
  color: rgb(228, 224, 224);
  display: flex;
}
.badge h2 img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: white;
  filter: brightness(0) invert(1);
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 153px;
  width: 210px;
  height: 210px;
  transition: 0.3s ease-in-out;
  border: 8px dotted white;
}
.arrow {
}
.arrow img {
  color: white;
  filter: brightness(0) invert(1);
}
/* .badge:hover {
  border-radius: 50%;
  background: linear-gradient(145deg, #3f4b70, #4b5985);
  box-shadow: 15px 15px 30px #21273a, -15px -15px 30px #6b7fbe;
  transition: 0.3s ease-in-out;
  scale: 1.04;
} */
@media screen and (max-width: 704px) {
  .badges {
    flex-wrap: wrap;
    gap: 23px;
  }
  .arrow {
    display: none;
  }
  .title {
    color: white;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 50px;
  }
}
