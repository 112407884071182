.innerCard {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  margin: auto;
  box-shadow: 0.5px 0.5px 3px 2px rgba(24, 57, 108, 1);
  border-radius: 10px;
}

.seats {
  display: flex;
  gap: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
  align-items: center;
}
.seats p {
  margin-top: 5px;
}
.share {
  color: white;
  font-size: 25px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.Free {
  font-size: 25px;
  color: white;
}
.applied {
  font-size: 13px;
  color: white;
  margin-top: 2px;
  color: rgb(39, 240, 39);
  display: flex;
  gap: 2px;
}
.icon {
  margin-top: 2px;
  cursor: pointer;
  color: white;
}
.cardInfo {
  padding: 5px 20px;
}
.innerCard .image {
  width: 100%;
  padding: 5px;
}
.image img {
  width: 100%;
  height: 220px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.price {
  display: flex;
  align-self: start;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
.price1 {
  color: white;
  font-size: 24px;
  font-weight: 700;
}
.oldPrice {
  text-decoration: line-through;
  font-size: 14px;
  color: grey;
}
.discount {
  background-color: orange;
  padding: 5px 10px;
  border-radius: 5px;
}
.seats p {
  color: white;
  margin-bottom: 10px;
}
.date {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.date p {
  color: white;
}

.submit button {
  padding: 10px 30px;
  background-color: #18396c;
  width: 100%;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: 10px;
}
.cardFooter {
  display: flex;
  justify-content: center;
}
.cardFooter a {
  color: white;
  text-align: center;
  cursor: pointer;
}
.coupon {
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;
}

.couponText {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.applyCoupon {
  display: flex;
  gap: 5px;
}
.inputWrapper {
  position: relative;
  width: 100%;
}

.applyCoupon input {
  padding: 10px 15px;
  margin-bottom: 5px;
  border-color: rgb(16, 213, 16);
  border-width: 2px;
  border: none;
  width: 100%;
}
.applyCoupon input:focus {
  outline: none;
}

.applyCoupon button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 15px;
  background-color: #18396c;
  color: white;
  margin-bottom: 10px;
  width: 30%;
}

@media screen and (max-width: 650px) {
  .innerCard {
    width: 100%;
  }
  .coupon {
    margin-top: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
  }

  .date p {
    font-size: 12px;
  }
}
