.card {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  margin: auto;
  box-shadow: 0.5px 0.5px 3px 2px rgba(24, 57, 108, 1);
  /* border: 1px solid white; */
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
}
.cardInfo {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.cardHeader h1 {
  color: white;
  font-size: 27px;
  font-weight: 700;
}
.courseLectures {
  display: flex;
  align-items: center;
  gap: 10px;
}
.courseLectures p {
  color: white;
  font-size: 18px;
}
.number {
  color: rgba(233, 229, 229, 0.807);
  font-size: 18px;
}

@media screen and (max-width: 650px) {
  .card {
    width: 100%;
  }
}
