.header {
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
@media screen and (max-width: 400px) {
  .header h2 {
    font-size: 1.1rem;
  }
}
