.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 85%;
  margin: auto;
  column-gap: 20px;
}
.noCourses1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.skeletons {
  display: flex;
  gap: 100px;
}
.noCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 400px;
}
.noCourses h1 {
  font-size: 40px;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 5%;
  /* margin-left: 30px !important;  */
}
.white {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customPagination {
  display: flex;
}
.customPagination button {
  width: 40px;
  height: 40px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  text-align: center;
  margin: 0 5px;
  text-decoration: none;
}

.customPagination button.prev,
.customPagination button.next {
  width: 70px;
  height: 40px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.pages {
  display: flex;
  align-items: center;
}
.pages button {
  display: flex;
}
.customPagination button.active,
.customPagination button:hover {
  background-color: #041024;
  color: #fff;
}
.courseSection {
  margin-top: 100px;
}
.courseSection h2 {
  font-size: 3rem;
  font-weight: 600;
  color: white;
  text-align: center;
}
.skeletonCard {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #092043;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}

.skeletonCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #092043 0%, #1c4d81 50%, #092043 100%);
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.skeletonCard > :first-child {
  margin-bottom: 20px;
}

.skeletonCard > :last-child {
  margin-bottom: 10px;
}

@media screen and (max-width: 1166px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }
}

@media screen and (max-width: 846px) {
  .cardContainer {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 30px;
    width: 100%;
  }
  .courseSection {
    margin-top: 150px;
  }
  .courseSection h2 {
    font-size: 2rem;
  }
}
