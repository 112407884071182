.upperSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.lowerSection {
  margin-bottom: 4%;
}
.header h2 {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.description p {
  color: rgb(255, 255, 255);
}
.singleLearn {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.topic p {
  color: #fffdfd;
}

@media screen and (max-width: 500px) {
  .topic p {
    color: rgb(255, 255, 255);
    font-size: 14px;

    width: 250px;
  }
}
@media screen and (max-width: 380px) {
  .topic p {
    color: rgb(255, 255, 255);
    font-size: 14px;

    width: 200px;
  }
}
@media screen and (max-width: 330px) {
  .topic p {
    color: rgb(255, 255, 255);
    font-size: 14px;

    width: 150px;
  }
}

