.card {
  width: 300px;
  height: 350px;
  background-color: #0e2549;
  padding: 2rem 1.5rem;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  border-radius: 10px;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

/*Image*/
.card-avatar {
  --size: 60px;
  border-radius: 50%;
  transition: transform 0.2s ease;
  margin-bottom: 1rem;
}

.card-avatar img {
  border-radius: 50%;
}

/*Card footer*/
.card-social {
  transform: translateY(200%);
  display: flex;
  justify-content: space-around;
  width: 100%;
  opacity: 0;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.card-social__item {
  list-style: none;
}

.card-social__item svg {
  display: block;
  height: 18px;
  width: 18px;
  fill: #ffffff;
  cursor: pointer;
  transition: fill 0.2s ease, transform 0.2s ease;
}

/*Text*/
.card-title {
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 2rem;
}

.card-subtitle {
  color: #859ba8;
  font-size: 0.8em;
}
.cardDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2px;
}
.cardDescription p {
  color: rgb(231, 224, 224);
  font-size: 15px;
}

/*Hover*/
.card:hover {
  box-shadow: 0 8px 50px #23232333;
}

.card:hover .card-info {
  transform: translateY(-5%);
}

.card:hover .card-social {
  transform: translateY(100%);
  opacity: 1;
}

.card-social__item svg:hover {
  fill: #ffffff;
  transform: scale(1.1);
}

.card-avatar:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 310px) {
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .card {
    width: 100%;
  }
  .card {
    width: 300px;
    height: 350px;
    background-color: #0e2549;
    padding: 2rem 1.5rem;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 8px 50px #23232333;
    border-radius: 10px;
  }
  .card-info {
    transform: translateY(-5%);
  }
  .card-social {
    transform: translateY(100%);
    opacity: 1;
  }
}
