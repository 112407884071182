.aboutContainer {
  margin-top: 100px;
  margin: 120px auto auto auto;
}

.aboutContainer h3 {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 50px;
  font-weight: 600;
}
.aboutDescription {
}
.aboutDescription p {
  color: rgb(255, 250, 250);
  font-size: 19px;
  margin-bottom: 18px;
  list-style-type: disc;
  line-height: 25px;
  width: 100%;
}

.Logo {
  width: 100%;
  height: 100%;
}
.Logo img {
  width: 1000px;
  height: 320px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  max-width: 90%;
  margin: auto;
}

.about {
  padding: 20px;
}
.teamSection {
  margin-top: 50px;
}
.teamContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 50px;
}
.teamTitle {
  text-align: center;
  color: white;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 50px;
}
@media screen and (max-width: 1044px) {
  .teamContainer {
    flex-wrap: wrap;
    gap: 20px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 20px;
    max-width: 100%;
    margin: auto;
  }
  .Logo {
    width: 80%;
    height: 80%;
  }
  .teamTitle {
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 500px) {
  .Logo {
    width: 90%;
    height: 90%;
  }

  .aboutDescription p {
    color: rgb(255, 250, 250);
    font-size: 17px;
    margin-bottom: 18px;

    line-height: 25px;
    width: 100%;
  }
}
