.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  padding: 10px;
  height: fit-content;
  min-height: 240px;
  color: white;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.card-container:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.image-container {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-container img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 100%;
  border: 3px solid white;
}
.personal-info {
  width: 100%;
  height: 50%;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 7px;

  flex-direction: column;
}
.personal-info h4 {
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}
.personal-info p {
  color: lightgray;
  font-size: 13px;
  text-align: center;
}
.personal-info div {
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.info-details {
  word-wrap: break-word;
}

@media screen and (max-width: 542px) {
  .card-container {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
@media screen and (max-width: 440px) {
  .card-container {
    background-color: rgba(255, 255, 255, 0.2);
    width: 160px;
  }
  .personal-info h4 {
    font-size: 13px;
  }
}
@media screen and (max-width: 361px) {
  .card-container {
    background-color: rgba(255, 255, 255, 0.2);
    width: 145px;
  }
}
@media screen and (max-width: 320px) {
  .card-container {
    background-color: rgba(255, 255, 255, 0.2);
    width: 190px;
  }
}
