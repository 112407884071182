.courseIntro {
  height: 350px;
  background-color: #0e2549;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10%;
  gap: 20px;
  margin-top: 85px;
}
.lowerContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 70px;
  grid-row-gap: 0px;
  padding: 30px;
  width: 95%;
  margin: auto;
}
.Tabs {
  grid-area: 1/1/2/2;
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.courseIntro h1 {
  color: white;
  font-size: 40px;
  font-weight: 700;
}

.courseIntro p {
  color: rgb(240, 234, 234);
  font-size: 17px;
  width: 75%;
}
.points {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.point {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pointIcon {
  color: white;
  font-size: 25px;
}
.pointIcon1 {
  color: white;
  font-size: 25px;
}
.pointIcon2 {
  color: blue;
  font-size: 25px;
}
.pointText {
  color: white;
}

.star {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  gap: 6px;
}
.star p {
  font-size: 20px;
  color: white;
}
/* singleCourse.module.css */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.skeleton {
  background-color: #092043;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.skeletonText {
  background-color: #092043;
  height: 20px;
  width: 100%;
  margin-bottom: 10px;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.skeletonRating {
  background-color: #092043;
  height: 30px;
  width: 120px;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.skeletonCard {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #092043;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}

.skeletonCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #092043 0%, #1c4d81 50%, #092043 100%);
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 1260px) {
  .lowerContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 30px;
    width: 90%;
    margin: auto;
  }

  .cards {
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    margin-top: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .Logo {
    width: 90%;
    height: 90%;
  }
}

@media screen and (max-width: 861px) {
  .cards {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 658px) {
  .lowerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card1 {
    margin-bottom: 50px;
    width: 100%;
  }
  .card2 {
    order: 2;
    width: 100%;
  }

  .card3 {
    order: 3;
    width: 100%;
    margin-top: 50px;
  }
  @media screen and (max-width: 650px) {
    .courseIntro {
      height: 350px;
      background-color: #3e5a8e;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 5%;
      gap: 20px;
    }
    .courseIntro p {
      width: 100%;
      font-size: 15px;
    }
    .courseIntro h1 {
      color: white;
      font-size: 20px;
      font-weight: 700;
    }
  }
}
