.review {
  display: flex;
  gap: 20px;
  margin-bottom: 3%;
  margin-top: 2%;
}
.image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.name {
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}
.rating {
  display: flex;
  align-items: center;
  gap: 5px;
}
.nameOfReviewer {
  gap: 10px;
}
.reviewText {
  color: white;
}
.infoReview {
  display: flex;
  flex-direction: column;
}
.nameOfReviewer {
  display: flex;
}

@media screen and (max-width: 650px) {
  .review {
    display: flex;
    gap: 20px;
    margin-bottom: 3%;
    margin-top: 2%;
  }
  .image img {
    width: 100px;
    height: 60px;
    border-radius: 50%;
  }
  .reviewText{
    font-size: .8rem;
  }
  .name {
    color: white;
    font-weight: bold;
    font-size: 1rem;
  }
}
