/* APPEAR */
.cardTime {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 0.8rem;
}
.price {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  margin-top: 5px;
}
.price1 {
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.oldPrice {
  text-decoration: line-through;
  font-size: 14px;
  color: grey;
}
.discount {
  background-color: orange;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 12px;
}

.card {
  width: 400px;
  height: 553px;
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  margin: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.779); /* Add box shadow */
  border-radius: 15px;
  background-color: #0e2549;
}

.cardDuration {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardDuration p {
  color: white;
  font-size: small;
}
.card h2 {
  z-index: 1;
  color: white;
  font-size: 2em;
}
.cardDate {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cardDate p {
  font-size: small;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Image */
.image {
  margin-bottom: 7px;
  transition: transform 0.3s ease-in-out;
}
.image img {
  height: 250px;
  width: 370px;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
}

/* .image:hover img {
  transform: scale(1.1);
}

.image:hover {
  border-radius: 15px;
} */
/* INFORMATION */

.card:hover {
  transform: scale(1.01);
}

.info {
  width: 100%;
  padding-left: 8px;
}

.title {
  color: white;
  font-weight: 700;
  font-size: 23px;
  width: 100%;
  /* padding-top: 2%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px 5px 12px;
}
.level {
  font-size: 15px;
  font-weight: 400;
  background-color: #1651ab;
  padding: 4px;
  border-radius: 6px;
  color: white;
}

.title h3 {
  font-size: 16px;
}

.desc {
  font-weight: 400;
  width: 95%;
  color: rgba(240, 231, 231, 0.683);
  line-height: normal;
  text-align: left;
  height: 50px;
}
.desc h5 {
  padding-left: 3%;
}

.submit {
  background-color: #1b4481;
  width: 90%;
  padding: 6px;
  border-radius: 5px;
  color: white;
  transition: 0.4s ease-in-out;
}
.submit:hover {
  background-color: #173b71;
  width: 90%;
  padding: 6px;
  border-radius: 5px;
  color: white;
}
.AuthorImage {
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}
.AuthorImage h4 {
  color: white;
  margin-top: 3px;
  gap: 2px;
  font-size: 16px;
}
.Author {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  gap: 0.7rem;
}

@media screen and (max-width: 420px) {
  .card {
    width: 340px;
    place-content: start;
  }
  .title {
    color: white;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px 5px 8px;
  }
  .title h3 {
    font-size: 14px;
  }
  .price {
    padding: 3px;
    width: 130px;
    justify-content: flex-end;
  }
  .submit {
    background-color: #1b4481;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    transition: 0.4s ease-in-out;
  }
  .AuthorText h4 {
    font-size: 14px;
  }
  .image {
    padding: 5px 5px 0px 5px;
  }
  .level {
    font-size: 13px;
    font-weight: 400;
    background-color: #1651ab;
    padding: 6px;
    border-radius: 6px;
    color: white;
  }
  .price1 {
    color: white;
    font-size: 12px;
    font-weight: 400;
  }
  .oldPrice {
    text-decoration: line-through;
    font-size: 12px;
    color: grey;
  }
  .discount {
    background-color: orange;
    padding: 3px 3px;
    border-radius: 5px;
    font-size: 11px;
  }
  .price {
    gap: 5px;
  }
}
@media screen and (max-width: 310px) {
  .card {
    width: 100%;
    place-content: center;
  }
}
