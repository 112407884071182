.contactSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 90px;
  margin-top: 130px;
}

.contact h3 {
  text-align: center;
  font-size: 39px;
  font-weight: 600;
  color: white;
  margin-bottom: 15px;
}
.contact span {
  background-color: #1b4481;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sendButton {
  border-radius: 15px;
  background-color: #1b4481;
  width: 220px;
  padding: 10px;
  border: none;
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.container form input[type="text"],
.container form input[type="email"],
.container form input[type="file"],
.container form input[type="url"],
.container form textarea {
  display: block;
  width: 940px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
}

.container form input[type="text"]:focus,
.container form input[type="email"]:focus,
.container form input[type="file"]:focus,
.container form input[type="url"]:focus,
.container form textarea:focus {
  background-color: #ddd;
  outline: none;
}

.container form button[type="submit"] {
  background-color: #1b4481;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}

.container form button[type="submit"]:hover {
  background-color: #1a4079;
}

/* Center the form */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-resize {
  resize: none;
}

.sendButton {
  display: block;
  width: 100%;
}
@media screen and (max-width: 737px) {
  .container form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 500px;

    padding: 20px;
  }
  .container form input[type="text"],
  .container form input[type="email"],
  .container form input[type="url"],
  .container form input[type="file"],
  .container form textarea {
    display: block;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    width: 100%;
  }
  .contact {
    margin-top: 150px;
    /* display: none; */
  }
  .contact h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 500px) {
  .contactSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 100px;
    margin-top: 20px;
  }

  .container form {
    width: 100%;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }
}
