.links {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 7px;
  width: 220px;
  justify-content: space-between;
}
footer {
  background-color: #18396c;
}
.submit {
  background-color: #1b4481;
  width: 100px;
  height: 35px;
  color: white;
  transition: 0.4s ease-in-out;
}
.newsLetter p {
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.input input {
  height: 35px;
  width: 355px;

  padding: 2px 10px;
}

/* .newsLetter button {
  padding: 6px 30px;
  background-color: #152f57;
  color: white;
  border: 1px solid white;
} */

.input input::placeholder {
  padding: 2px 10px;
}

input:focus {
  outline: none;
}

.contactInfo {
  width: 100%;
  margin-left: 10px;
}
.container {
  max-width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding: 2%;
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  column-gap: 15%;
}
.footerTitle {
  display: flex;

  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
  gap: 10px;
}
.footerParagraph {
  color: rgb(255, 255, 255);
}

.footerPagesTitle {
  color: white;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 15px;
}
.item {
  margin-bottom: 10px;
  text-decoration: underline;
  color: white;
}
.contactTitle {
  color: white;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 15px;
}
.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 10px;
  width: 90%;
}
.footerLastSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  padding: 10px;
}
.footerEnd {
  font-size: 22px;
  color: white;
}

.icon {
  color: white;
  font-size: 28px;
  margin-right: 20px;
  padding: 13px;
}
/* .icon:hover {
  background-color: #0a3b84;
  border-radius: 50%;
} */

@media screen and (max-width: 785px) {
  .container {
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding: 2%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 20px;
  }
  .newsLetter p {
    display: none;
  }
  .footerPages {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .contact {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  .footerLastSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    width: 95%;
    margin: auto;
    padding: 10px;
  }
}

@media screen and (max-width: 539px) {
  .input input {
    height: 43px;
    width: 240px;
    padding: 2px 10px;
  }
  .submit {
    height: 43px;
    margin-left: 0px;
  }
  .newsLetter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px;
  }
}
.newsLetter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 10px;
}

@media screen and (max-width: 360px) {
  .input input {
    height: 40px;
    width: 100%;
    padding: 2px 10px;
  }
  .submit{
    width: 100%;
  }
  /* .newsLetter .submit {
    padding: 10px 20px;
    background-color: #152f57;
    color: white;
    border: 1px solid white;
    width: 100%;
  } */
  .newsLetter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
  }
}