.cirriculum {
  list-style: disc;
  margin-left: 1.5rem;
}
.cirriculum li {
  margin-bottom: 0.5rem;
  font-size: 18px;
}
@media screen and (max-width: 420px) {
  .title {
    font-size: 0.9rem !important;
    /* display: none; */
  }
  .content{
    font-size: 0.8rem !important;
  }
}
