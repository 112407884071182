.cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  border-radius: 5px;
  margin: auto;
  margin-top: 100px;
  gap: 20px;
  margin-bottom: 100px;
  cursor: default;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 5%;
  /* margin-left: 30px !important;  */
}
.white {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customPagination {
  display: flex;
}
.customPagination a {
  width: 40px;
  height: 40px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  text-align: center;
  margin: 0 5px;
  text-decoration: none;
}

.customPagination a.prev,
.customPagination a.next {
  width: 70px;
  height: 40px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.pages {
  display: flex;
  align-items: center;
}
.pages a {
  display: flex;
}
.customPagination a.active,
.customPagination a:hover {
  background-color: #041024;
  color: #fff;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 5%;
  /* margin-left: 30px !important;  */
}
.title-wrapper {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.noChampions {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
}

.cards-grid {
  width: 100%;
  padding: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, 1fr);
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 3%;
  margin-bottom: 5%;
  /* margin-left: 30px !important;  */
}
.white {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customPagination {
  display: flex;
}
.customPagination button {
  width: 40px;
  height: 40px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  text-align: center;
  margin: 0 5px;
  text-decoration: none;
}

.customPagination button.prev,
.customPagination button.next {
  width: 70px;
  height: 40px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.pages {
  display: flex;
  align-items: center;
}
.pages button {
  display: flex;
}
.customPagination button.active,
.customPagination button:hover {
  background-color: #041024;
  color: #fff;
}

@media screen and (max-width: 1440px) {
  .cards-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 1240px) {
  .cards-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 940px) {
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 542px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .title-wrapper {
    font-size: 26px;
  }
}
@media screen and (max-width: 440px) {
  .title-wrapper {
    font-size: 20px;
  }
}
@media screen and (max-width: 340px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
