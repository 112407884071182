.instructorContainer {
  display: flex;
  gap: 10px;
}
.leftContainer {
  display: flex;
  flex-direction: column;
}
.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topic p {
  color: #ffffff;
}
.listingTopics {
  margin-top: 100px;
}
.singleLearn {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.name {
  color: rgb(255, 255, 255);
  font-size: 1.8rem;
  font-weight: bold;
}

.field {
  color: rgb(168, 164, 164);
  font-size: 0.9rem;
  font-weight: bold;
}
.info {
  margin-top: 5%;
  display: flex;
  gap: 30px;
  color: rgb(255, 255, 255);
}
.info h2 {
  font-weight: bold;
}
.infoDescription {
  margin-top: 8%;
  color: rgb(255, 255, 255);
}
.infoDescription h2 {
  margin-bottom: 2%;
  font-weight: bold;
}
.image img {
  width: 430px;
  height: 180px;
  border-radius: 50%;
}
.desc {
  width: 90%;
  color: white;
}
.links {
  display: flex;
  gap: 20px;
  font-size: 30px;
  padding-top: 30px;
}
.aboutInstructor {
  margin-top: 30px;
}
.header {
  color: rgb(225, 221, 221);
  font-size: 1.3rem;
  font-weight: 500;
}
.aboutInfo {
  color: grey;
}

@media (max-width: 562px) {
  .instructorContainer {
    display: flex;
    flex-direction: column-reverse;
  }
  .image img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
}
